<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
//import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "FactorAnalysisReturnHistogramChart",
  props: {
    data: Object
  },
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "25%",
          bottom: "8%",
          left: "45",
          right: "40",
          containLabel: true
          //bottom: "3%"
        },

        toolbox: {
          show: true,
          feature: {
            magicType: {
              type: ["line", "bar"]
            },
            // restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: [
          {
            text: "Sorted Past Signals Returns",
            left: "30",
            top: "5%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          }
        ],
        tooltip: {
          trigger: "axis",
          label: {
            show: false
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              shadowBlur: 0,
              color: "rgba(100, 100, 100, 0.1)"
            },
            animation: false,
            label: {
              show: false,
              backgroundColor: "#282829",
              fontSize: 15,
              precision: 0
            }
          },
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          show: false,
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: [
          {
            type: "value",
            name: "Returns",
            show: true,
            position: "left",
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["grid"] = {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "23%",
          bottom: "8%",
          left: "10",
          right: "10",
          containLabel: true
          //bottom: "3%"
        };
        this.options["toolbox"] = { show: false };
        // this.options["toolbox"]["show"] = false;
        // this.options["yAxis"][0]["show"] = false;
        // this.options["yAxis"][1]["show"] = false;
        // this.options["tooltip"]["confine"] = true;
      }
    },
    setOptions: function() {
      var series = [];
      Object.keys(this.data).forEach(key => {
        series.push({
          name: key,
          barGap: "-100%",
          itemStyle: {
            color: "rgba(109, 89, 122, 1)",
            opacity: 0.6,
            borderWidth: 1,
            borderType: "solid",
            borderColor: "rgba(14, 14, 14, 0.9)"
          },
          type: "bar",
          data: this.data[key]
        });
      });

      this.options["series"] = series;
      this.$refs.priceChart.setOption(this.options, true);
    }
  },
  watch: {
    data: function() {
      if (Object.keys(this.data).length > 0) {
        this.setChart();
        this.setOptions();
      }
    }
  },
  mounted() {
    if (this.data != undefined) {
      if (Object.keys(this.data).length > 0) {
        this.setChart();
        this.setOptions();
      }
    }
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
    // var reload = () => {
    //   console.log("destroy");
    //   setTimeout(reload, 500);
    //   this.setChart();
    // };
    // setTimeout(reload, 3000);
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
